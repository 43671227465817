<template>
  <div style="max-height: calc(100vh - 179px); overflow-y: auto">
    <v-data-table
      :loading="loading"
      :search="search"
      :page.sync="page"
      :headers="headers"
      :items="items"
      :items-per-page="5"
      item-key="id"
      fixed-header
      sort-by="name"
      sort-desc
      must-sort
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          single-line
          :label="$t('users.search')"
          prepend-inner-icon="mdi-magnify"
          outlined
        />
      </template>
      <template
        v-if="hasUserPermissionToViewEditOrManage('USERS')"
        v-slot:item.actions="{ item }"
      >
        <v-menu bottom absolute>
          <template v-slot:activator="{ on, attrs }">
            <v-btn depressed icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-if="hasUserPermissionToViewEditOrManage('USERS')"
              @click="permissions(item)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-lock</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("users.permissions")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="hasUserPermissionToViewEditOrManage('USERS')"
              @click="edit(item)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="hasUserPermissionToManage('USERS')"
              @click="remove(item)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Remover</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.locodes="{ item }">
        <div v-if="!!item.locodes">
          <v-chip
            label
            outlined
            small
            class="mr-1"
            v-for="locode in item.locodes"
            :key="locode"
          >
            {{ locode }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.is_ldap="{ item }">
        <v-chip
          v-if="item.is_ldap"
          label
          outlined
          small
          class="mr-1 text-uppercase"
          color="secondary darken-2"
          >{{ $t("users.ldap_user") }}</v-chip
        >
        <v-chip
          v-else
          label
          outlined
          small
          class="mr-1 text-uppercase"
          color="primary darken-2"
          >{{ $t("users.local_user") }}</v-chip
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      page: 1,
      loading: false,
      headers: [
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "30px",
        },
        {
          text: this.$t("users.name"),
          value: "name",
        },
        {
          text: this.$t("users.username"),
          value: "username",
        },
        {
          text: this.$t("users.email"),
          value: "email",
        },
        {
          text: this.$t("users.authentication_type"),
          value: "is_ldap",
        },
        {
          text: this.$t("users.locodes"),
          value: "locodes",
        },
        {
          text: this.$t("users.default_locode"),
          value: "default_locode",
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.users.usersList;
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("users/GET_USERS").then(() => {
      this.loading = false;
    });
  },
  methods: {
    permissions(item) {
      this.$emit("permissions", item);
    },

    edit(item) {
      this.$emit("edit", item);
    },

    remove(item) {
      this.$emit("remove", item);
    },
  },
};
</script>
