<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-progress-linear
        height="10"
        striped
        color="lime"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-lock</v-icon>{{ $t("users.permissions") }}</span
          >
        </v-toolbar-title>
        <v-spacer />
        <v-btn depressed icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-2">
        <v-alert
          dense
          color="blue"
          border="left"
          colored-border
          icon="mdi-information-outline"
          class="mb-1"
        >
          <span class="subtitle-2">{{ $t("users.permissions_information") }}</span>
          <v-list two-line dense>
            <v-list-item>
              <template>
                <v-list-item-icon>
                  <v-icon>mdi-eye-off</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    ><strong>{{
                      $t("global.no_access")
                    }}</strong></v-list-item-title
                  >
                  <v-list-item-subtitle class="text--primary">{{
                    $t("global.no_recurs")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <template>
                <v-list-item-icon>
                  <v-icon>mdi-eye</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    ><strong>{{
                      $t("global.only_view")
                    }}</strong></v-list-item-title
                  >
                  <v-list-item-subtitle class="text--primary">{{
                    $t("global.only_view_recurs")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <template>
                <v-list-item-icon>
                  <v-icon>mdi-eye-plus</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    ><strong>{{
                      $t("global.view_edit")
                    }}</strong></v-list-item-title
                  >
                  <v-list-item-subtitle class="text--primary text-wrap">{{
                    $t("global.view_edit_recurs")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <template>
                <v-list-item-icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    ><strong>{{
                      $t("global.full_access")
                    }}</strong></v-list-item-title
                  >
                  <v-list-item-subtitle class="text--primary">{{
                    $t("global.full_access_recurs")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list>
        </v-alert>
        <div>
          <v-simple-table fixed-header height="250px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left text-uppercase">Recurso</th>
                  <th width="80px" class="text-center">
                    <v-btn depressed
                      title="Seleccionar todos"
                      x-small
                      text
                      @click="selectAll('NO-ACCESS')"
                    >
                      <v-icon small left>mdi-eye-off</v-icon
                      >{{ $t("global.no_access") }}
                    </v-btn>
                  </th>
                  <th width="80px" class="text-center">
                    <v-btn depressed
                      title="Seleccionar todos"
                      x-small
                      text
                      @click="selectAll('VIEW')"
                    >
                      <v-icon small left>mdi-eye</v-icon
                      >{{ $t("global.only_view") }}
                    </v-btn>
                  </th>
                  <th width="80px" class="text-center">
                    <v-btn depressed
                      title="Seleccionar todos"
                      x-small
                      text
                      @click="selectAll('VIEW_EDIT')"
                    >
                      <v-icon small left>mdi-eye</v-icon
                      >{{ $t("global.view_edit") }}
                    </v-btn>
                  </th>
                  <th width="80px" class="text-center">
                    <v-btn depressed
                      title="Seleccionar todos"
                      x-small
                      text
                      @click="selectAll('MANAGE')"
                    >
                      <v-icon small left>mdi-pencil</v-icon
                      >{{ $t("global.full_access") }}
                    </v-btn>
                  </th>
                </tr>
              </thead>
              <tbody style="height: 250px; overflow-y: auto">
                <tr v-for="permission in permissions" :key="permission.id">
                  <td
                    v-if="
                      checkPermissions() || permission.code !== 'PLAN_VERSIONS'
                    "
                  >
                    <v-icon
                      left
                      v-text="permission.icon"
                    ></v-icon
                    >{{ getPermissionName(permission.code) }}
                  </td>
                  <td
                    class="text-center"
                    v-if="
                      checkPermissions() || permission.code !== 'PLAN_VERSIONS'
                    "
                  >
                    <v-radio-group row v-model="permission.profile">
                      <v-radio
                        v-if="permission.code !== 'VESSEL_CALLS'"
                        value="NO-ACCESS"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                  <td
                    class="text-center"
                    v-if="
                      checkPermissions() || permission.code !== 'PLAN_VERSIONS'
                    "
                  >
                    <v-radio-group row v-model="permission.profile">
                      <v-radio value="VIEW"></v-radio>
                    </v-radio-group>
                  </td>
                  <td
                    class="text-center"
                    v-if="
                      checkPermissions() || permission.code !== 'PLAN_VERSIONS'
                    "
                  >
                    <v-radio-group row v-model="permission.profile">
                      <v-radio value="VIEW_EDIT"></v-radio>
                    </v-radio-group>
                  </td>
                  <td
                    class="text-center"
                    v-if="
                      checkPermissions() || permission.code !== 'PLAN_VERSIONS'
                    "
                  >
                    <v-radio-group row v-model="permission.profile">
                      <v-radio value="MANAGE"></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed color="primary" text @click="save" :disabled="loading">
          {{ $t("global.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
const DEFAULT_PERMISSIONS = [
 {
    code: "MAP",
    profile: "NO-ACCESS",
    icon: "mdi-map"
  },
  {
    code: "VESSEL_CALLS",
    profile: "NO-ACCESS",
    icon: "mdi-ferry"
  },
  {
    code: "PLANS",
    profile: "NO-ACCESS",
    icon: "mdi-calendar-month-outline"
  },
  {
    code: "PLAN_VERSIONS",
    profile: "NO-ACCESS",
    icon: "mdi-numeric-9-plus-box-multiple-outline"
  },
  {
    code: "MOORING_BITTS",
    profile: "NO-ACCESS",
    icon: "mdi-window-shutter-open"
  },
  {
    code: "ZONES",
    profile: "NO-ACCESS",
    icon: "mdi-dots-square"
  },
  {
    code: "LAYERS",
    profile: "NO-ACCESS",
    icon: "mdi-layers"
  },
  {
    code: "SHIP_TYPES",
    profile: "NO-ACCESS",
    icon: "mdi-anchor"
  },
  {
    code: "HYDRO",
    profile: "NO-ACCESS",
    icon: "mdi-waves"
  },
  {
    code: "USERS",
    profile: "NO-ACCESS",
    icon: "mdi-account-group"
  },
  {
    code: "SHIPS",
    profile: "NO-ACCESS",
    icon: "mdi-ship-wheel"
  },
];
export default {
  props: ["open", "item"],
  data() {
    return {
      loading: false,
      permissions: [],
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
  },
  watch: {
    open() {
      this.permissions = JSON.parse(JSON.stringify(DEFAULT_PERMISSIONS));
      if (this.item && this.item.user_permissions) {
        this.item.user_permissions.forEach((up) => {
          let idx = this.permissions.findIndex((p) => {
            return p.code == up.code;
          });
          if (idx !== -1) {
            this.permissions.splice(idx, 1, up);
          }
        });
      }
    },
  },
  methods: {
    checkPermissions() {
      return (
        this.permissions.find((permission) => {
          return permission.code === "PLANS";
        }).profile === "MANAGE"
      );
    },
    close() {
      this.isOpen = false;
    },
    save() {
      this.loading = true;

      if (
        this.permissions.find((permission) => permission.code === "PLANS")
          .profile !== "MANAGE"
      ) {
        this.permissions.find(
          (permission) => permission.code === "PLAN_VERSIONS"
        ).profile = "NO-ACCESS";
      }
      this.$store
        .dispatch("users/UPDATE_USER_PERMISSIONS", {
          user: this.item,
          permissions: this.permissions,
        })
        .then(() => {
          this.loading = false;
          this.close();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getPermissionName(code) {
      return this.$t("permissions." + code.toLowerCase());
    },
    selectAll(profile) {
      if (this.permissions) {
        this.permissions.forEach((p) => {
          p.profile = profile;
        });
      }
    },
  },
};
</script>
